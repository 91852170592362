<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	import Gen from '@helper/Gen';
	import draggable from 'vuedraggable'

	export default {
		name: "AppSubscribe",
		extends: Base,
		components: {
			draggable,
		},
		data() {
			return {
				Name: "AppSubscribe",
				valCheck: "",
				data:{data:[]}
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods: {
			deleteItem(e, k) {
				var v = this.data.data[k]
				swal({
					title: "Are you sure to delete this " + this.ObjectName + "?",
					text: "",
					icon: "warning",
					buttons: ["No, Cancel!", "Yes, Sure!"],
					dangerMode: true,
				}).then((ok) => {
					if (ok) {
						BOGen.apirest("/" + this.Name, {
							type: "delete",
							id: v.id
						}, () => {
							// callback delete item
						}, "POST")
						this.data.data.splice(k, 1);
						swal("Deleted!", "Information has been deleted.", "success");
					}
				});
			},
			endDrag() {
				BOGen.apirest('/' + Page.Name, {
					data: this.data.data,
					type: 'sort'
				}, (err, resp) => {
					console.log(resp)
				}, "POST");
			}
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'() {
				this.search()
			},
			'valCheck'() {
				BOGen.apirest('/' + Page.Name, {
					status: this.valCheck,
					type: 'updateStatus'
				}, (err, resp) => {
					if (err) console.log(err)
					console.log("update success")
				}, "POST");
			}
		}
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<div class="row">
			<div class="col-12" v-if="!$route.params.id">
				<div class="card">
					<div class="row">
						<div class="col-12">
							<div class="card">
								<div class="card-body">
									<div class="d-flex align-items-center">
										<h5 class="card-title mb-0">Subscription</h5>
										<div class="custom-control custom-checkbox sub-check" v-if="page.moduleRole('ChangeStatusSubscribe')" >
											<Checkbox isOption v-model="valCheck">
												<span>Activate</span>
												<span class="activated">Activated</span>
											</Checkbox>
										</div>
									</div>
									<hr>

									<draggable class="row" v-model="data.data" tag="div" @end="endDrag">
										<div class="col-md-6" v-for="(v,k) in data.data" :key="k">
											<div class="card">
												<div class="card-body">
													<div class="product-img">
														<img :src="uploader(v.apc_image)" alt="">
														<div class="pro-img-overlay">
															<router-link class="bg-info" v-if="page.moduleRole('Update')" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'"><i class="ti-marker-alt"></i>
															</router-link>
															<a href="javascript:;" v-if="page.moduleRole('Remove')" @click="deleteItem($event,k)" class="bg-danger"><i class="ti-trash"></i></a>
														</div>
														
														<span class="label label-success" v-if="v.apc_is_active=='Y'">Active</span>
														<span class="label label-danger" v-if="v.apc_is_active=='N'">Inactive</span><br>
														<span class="label label-info" v-if="v.apc_is_featured=='Y'">Featured</span>
													</div>
													<div class="product-text">
														<h5 class="card-title text-center">{{v.apc_title_en}}</h5>
													</div>
												</div>
											</div>
										</div>
									</draggable>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12" v-if="$route.params.id">
				<VForm @resp="submitForm" method="post">
					<div class="row">
						<div class="col-12">
							<div class="card">
								<div class="card-body">
									<div class="d-flex justify-content-between align-items-center">
										<h5 class="card-title text-danger">Add Subscription Package</h5>
									</div>
									<hr>
										<div class="info"></div>
										<div class="row">
											<div class="col-md-4">
												<BoField :label="'Image Subscribe'">
													<Uploader name="apc_image" type="img_subscribe" uploadType="cropping"
														:param="{thumbnail:true}" v-model="row.apc_image">
													</Uploader>
												</BoField>
												<!-- <BoField :label="'Image Featured'">
													<Uploader name="apc_image_featured" type="img_subscribe_featured" uploadType="cropping"
														:param="{thumbnail:true}" v-model="row.apc_image_featured">
													</Uploader>
												</BoField> -->
												<div class="card border">
													<div class="card-body">
														<BoField name="apc_subscribe_phone" v-model="row.apc_subscribe_phone" :attr="{type:'Number'}" placeholder='62856xxxxxxxx'></BoField>
														<BoField name="apc_subscribe_text" class="mb-2">
															<textarea v-model="row.apc_subscribe_text" class="form-control"></textarea>
														</BoField>
													</div>
												</div>
													<BoField name="apc_is_active">
														<div class="row">
															<div class="col-3">
																<radio name="apc_is_active" v-model="row.apc_is_active" option="Y" :attr="validation('apc_is_active')">Active</radio>
															</div>
															<div class="col-3">
																<radio name="apc_is_active" v-model="row.apc_is_active" option="N">Inactive</radio>
															</div>
														</div>
													</BoField>

													<BoField name="apc_is_featured">
														<div class="row">
															<div class="col-3">
																<radio name="apc_is_featured" v-model="row.apc_is_featured" option="Y" :attr="validation('apc_is_featured')">Active</radio>
															</div>
															<div class="col-3">
																<radio name="apc_is_featured" v-model="row.apc_is_featured" option="N">Inactive</radio>
															</div>
														</div>
													</BoField>
											</div>
											<div class="col-md-8">
												<div class="row">
													<div class="col-8">
														<div class="form-group">
															<BoField name="apc_title_id" v-model="row.apc_title_id"></BoField>
															<BoField name="apc_title_en" v-model="row.apc_title_en"></BoField>
														</div>
													</div>
												</div>
												<div class="col-12">
													<div class="form-group">
														<BoField name="apc_desc_id">
															<CKEditor type='noImage' name="content" v-model="row.apc_desc_id">
															</CKEditor>
														</BoField>
													</div>
												</div>
												<div class="col-12">
													<div class="form-group">
														<BoField name="apc_desc_en">
															<CKEditor type='noImage' name="content1" v-model="row.apc_desc_en">
															</CKEditor>
														</BoField>
													</div>
												</div>
											</div>
										</div>
									<hr>
									<div class="d-flex justify-content-end">
										<button type="submit" class="btn btn-success btn-loading">Submit</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</VForm>
			</div>
		</div>
	</div>
</template>